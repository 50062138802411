<template>
  <AppointmentView></AppointmentView>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AppointmentViewOutsite",

  components: {
    AppointmentView: () => import("./AppointmentView"),
  },
};
</script>
